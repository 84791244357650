import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { formateOptions, formateOptionsForFetchData, urlToObject } from "../../util/report";
import Table from "./Table";
import Setting from "./Settings";
import Filters from "./Filters";
import DataRange from "./DateRange";
import Loader from "../../components/UI/Loader";
import Pages from "../../widgets/Pages";
import "./report.css";
import SelectGroup from "./SelectGroup";
import UserSelect from "../../components/UserSelect";
import { UserContext } from "../../context/User";
import Alert from "../../components/UI/Alert";
import { getResponseErrorsList } from "../../util/util";
import Button from "../../components/UI/Button";
import SecondUpdateIcon from "../../components/icons/SecondUpdateIcon";
import { DEFAULT_REPORT_COLUMN_WIDTHS_KEY, DEFAULT_REPORT_OPTION_KEY } from "../../constants/report";

const initialAlert = {
    isVisible: false,
    variant: "error",
    message: "",
    duration: 5000,
};

const ReportInstalls = () => {
    const [data, setData] = useState(null);
    const [options, setOptions] = useState(null);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateData, setUpdateData] = useState(null);

    const [alert, setAlert] = useState(initialAlert);
    const [searchParams, setSearchParams] = useSearchParams();

    const userContext = useContext(UserContext);

    const search = window.location.search?.substring(1);

    const updateQuery = (opts) => {
        const newParams = new URLSearchParams();
        Object.keys(opts).forEach((key) => {
            let value = opts[key];
            if (["filters"].includes(key)) {
                value = JSON.stringify(value);
            } else if (key === "user_id") return;
            newParams.set(key, value);
        }); // Добавляем/изменяем параметр
        setSearchParams(newParams, { replace: true }); // Обновляем URL без перезагрузки
    };

    const showAlert = ({ message, variant = "error", duration = 5000 }) => {
        let msg = message;
        if (typeof message === "object") {
            msg = Object.values(message)[0];
        }
        setAlert({ isVisible: true, variant, message: msg, duration });
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const opts = formateOptionsForFetchData(options);
                updateQuery(opts);

                delete opts.dateRangeValue;
                const { data } = await axios.post("/report/installs", opts);
                userContext.setFavData(DEFAULT_REPORT_OPTION_KEY, window.location.search.substring(1), true);

                setData(data.report);
                setIsLastPage(data.isLast);
            } catch (err) {
                showAlert({ message: getResponseErrorsList(err) });
            } finally {
                setIsLoading(false);
            }
        };
        if (options) {
            fetchData();
        }
    }, [options, updateData]);

    useEffect(() => {
        const getOptions = async () => {
            try {
                const { data } = await axios.post("/report/installs/options");

                let customOptions = {};

                const userOptions = userContext.getFavData(DEFAULT_REPORT_OPTION_KEY);
                const columnWidths = userContext.getFavData(DEFAULT_REPORT_COLUMN_WIDTHS_KEY);
                if (search) {
                    customOptions = urlToObject(search);
                } else if (userOptions) {
                    customOptions = urlToObject(userOptions);
                }

                setOptions(formateOptions(data, customOptions, columnWidths));
            } catch (err) {
                showAlert({ message: getResponseErrorsList(err) });
            }
        };
        getOptions();
    }, []);

    const handlerUpdateBtn = () => {
        setUpdateData(Date.now());
    };

    const updateOptions = (newOptions) => {
        setOptions((prev) => ({ ...prev, ...newOptions }));
    };

    const handlerChangePage = (nextPage) => {
        updateOptions({ page: nextPage });
    };

    const saveColumnWidths = (columnWidths) => {
        userContext.setFavData(DEFAULT_REPORT_COLUMN_WIDTHS_KEY, columnWidths, true);
    };

    return (
        <div className="page report-page" id="report-page">
            <Alert
                variant={alert.variant}
                message={alert.message}
                isVisible={alert.isVisible}
                duration={alert.duration}
                onClose={() => setAlert({ ...alert, isVisible: false })}
            />
            <div className="report-header">
                <div />
                <h1>Отчет</h1>
                <div className="user-select-block">
                    <UserSelect
                        selectedUserId={options?.user_id}
                        setSelectedUserId={(user_id) => updateOptions({ user_id })}
                    />
                </div>
            </div>
            {data && options ? (
                <>
                    <div className="report-head">
                        <div className="left-block">
                            <Filters
                                selectedFilters={options.selectedFilters}
                                initialFilters={options.initial_filters}
                                filterOperators={options.filter_operators}
                                updateOptions={updateOptions}
                            />
                            <SelectGroup
                                selectedGroups={options.selectedGroups}
                                initialGroups={options.initial_groups}
                                updateOptions={updateOptions}
                            />
                        </div>
                        <div className="right-block">
                            <DataRange
                                dateRangeValue={options.dateRangeValue}
                                updateOptions={updateOptions}
                                timezone={options.timezone}
                                timezones={options.timezones}
                                timeStart={options.time_start}
                                timeEnd={options.time_end}
                            />
                            <Button
                                customClassName={`update-btn ${isLoading ? "rotate-icon" : ""} `}
                                size="small"
                                withoutRounded
                                onClick={handlerUpdateBtn}
                                title={<SecondUpdateIcon />}
                            />

                            <Setting initialColumns={options.columns} updateOptions={updateOptions} />
                        </div>
                    </div>
                    <Table
                        saveColumnWidths={saveColumnWidths}
                        updateOptions={updateOptions}
                        columns={options.columns}
                        data={data}
                        sortBy={options.sort_by}
                        order={options.sort_order}
                        isLoading={isLoading}
                    />
                    <div className="footer">
                        <Pages
                            style={{ margin: "0 auto", width: "auto" }}
                            handler={handlerChangePage}
                            current={+options.page}
                            isLast={isLastPage}
                        />
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </div>
    );
};

export default ReportInstalls;
