import { useState } from "react";
import Button from "../../components/UI/Button";
import ModalWindow from "../../widgets/ModalWindow";
import Select from "react-select";
import CloseIcon from "../../components/icons/CloseIcon";

const getFilters = (selectedFilters, initialFilters) => {
    const filters = [];
    selectedFilters.forEach((filter, index) => {
        const type = initialFilters.find(({ field }) => field === filter.field)?.type;
        const id = Date.now() + index;
        filters.push({ ...filter, type, id });
    });

    if (!filters.length) {
        // one empty filter by default
        filters.push({ id: Date.now() });
    }

    return filters;
};

const Filters = ({ selectedFilters, initialFilters, filterOperators, updateOptions }) => {
    const [filters, setFilters] = useState(getFilters(selectedFilters, initialFilters));
    const [isOpenModal, setIsOpenModal] = useState(false);

    const closeModal = () => {
        setIsOpenModal(false);
        setFilters(getFilters(selectedFilters, initialFilters));
    };
    const openModal = () => setIsOpenModal(true);

    const handlerApplyBtn = () => {
        const newSelectedFilters = [];

        filters.forEach((filter) => {
            if (Object.keys(filter).length === 1) return; // filter is empty

            newSelectedFilters.push({
                field: filter.field,
                operator: filter.operator,
                value: filter.value,
            });
        });

        updateOptions({
            selectedFilters: newSelectedFilters,
        });
        setIsOpenModal(false);
    };

    const addFilter = () => {
        setFilters((prev) => [...prev, { id: Date.now() }]);
    };

    const removeFilter = (id) => {
        if (filters.length !== 1) {
            setFilters((prev) => prev.filter((item) => item.id !== id));
            return;
        }
        // clean the last filter
        setFilters([{ id }]);
    };

    const updateFilter = (newValue, id) => {
        const filterCopy = [...filters];
        const index = filterCopy.findIndex((item) => item.id === id);
        filterCopy[index] = { ...filters[index], ...newValue };
        delete filterCopy[index].label;
        setFilters(filterCopy);
    };

    const findOperator = (operator, type) => filterOperators[type]?.find((opt) => opt.operator === operator) || "";

    return (
        <div>
            <Button
                title={`Фильтры ${selectedFilters.length ? selectedFilters.length : ""}`}
                withoutRounded
                size="small"
                onClick={openModal}
            />
            <ModalWindow
                className="report-filters-modal"
                isVisible={isOpenModal}
                onClose={closeModal}
                anchorContainerID="report-page"
                closeButton
            >
                <h1>Фильтры</h1>
                <div className="filter-list">
                    {filters.map((item) => (
                        <div className="filter-item" key={item.id}>
                            <div
                                className={`delete-btn ${
                                    filters.length === 1 && Object.keys(item).length === 1 ? "hidden" : ""
                                }`}
                                onClick={() => removeFilter(item.id)}
                            >
                                <CloseIcon />
                            </div>
                            <Select
                                className="select small"
                                value={initialFilters.find((opt) => opt.field === item.field) || ""}
                                getOptionValue={(e) => e.field}
                                options={initialFilters}
                                onChange={(newValue) => updateFilter(newValue, item.id)}
                            />
                            <Select
                                className="select small"
                                value={findOperator(item.operator, item.type)}
                                getOptionValue={(e) => e.operator}
                                options={filterOperators[item.type]}
                                isDisabled={!Boolean(filterOperators[item.type])}
                                onChange={(newValue) => updateFilter(newValue, item.id)}
                            />

                            <input
                                type="text"
                                disabled={!Boolean(filterOperators[item.type])}
                                value={item.value || ""}
                                onChange={(e) => updateFilter({ value: e.target.value }, item.id)}
                            />
                        </div>
                    ))}
                    <Button title="Добавить" withoutRounded variant="warning" onClick={addFilter} size="small" />
                </div>

                <div className="buttons-block">
                    <Button onClick={closeModal} title="Отмена" withoutRounded variant="error" />
                    <Button withoutRounded title="Применить" onClick={handlerApplyBtn} />
                </div>
            </ModalWindow>
        </div>
    );
};

export default Filters;
