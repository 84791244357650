import { useMemo, useState } from "react";
import { SortableContainer, SortableElement, arrayMove } from "react-sortable-hoc";
import HamburgerIcon from "../../components/icons/HamburgerIcon";
import SettingIcon from "../../components/icons/SettingIcon";
import Button from "../../components/UI/Button";
import ModalWindow from "../../widgets/ModalWindow";

const SortableItem = SortableElement(({ item, onToggle }) => (
    <div className={`sortable-column-item ${item.isHidden ? "disabled" : ""}`}>
        <div className="drag-handle">
            <HamburgerIcon />
        </div>
        <div className="sortable-column-item-content">
            <input
                type="checkbox"
                className="form-check-input"
                checked={!item.isHidden}
                onChange={() => onToggle(item.accessor)}
            />
            <div className="item-name">{item.Header}</div>
        </div>
    </div>
));

// 🔹 Контейнер списка
const SortableList = SortableContainer(({ items, onToggle, isDraggingDisabled }) => (
    <div>
        {items.map((item, index) => (
            <SortableItem key={item.accessor} index={index} item={item} onToggle={onToggle} />
        ))}
    </div>
));

const Setting = ({ initialColumns, updateOptions }) => {
    const [columns, setColumns] = useState(initialColumns);
    const [isOpenModal, setIsOpenModal] = useState(false);

    const closeModal = () => {
        setIsOpenModal(false);
        setColumns(initialColumns);
    };

    const openModal = () => setIsOpenModal(true);

    // Обработчик изменения чекбокса
    const handleToggle = (accessor) => {
        setColumns((prev) =>
            prev.map((col) => (col.accessor === accessor ? { ...col, isHidden: !col.isHidden } : col))
        );
    };

    // Обработчик сортировки
    const onSortEnd = ({ oldIndex, newIndex }) => {
        const newApps = arrayMove(columns, oldIndex, newIndex);
        setColumns(newApps);
    };

    const handlerApplyBtn = () => {
        updateOptions({ columns: columns.sort((a, b) => (a.isHidden === b.isHidden ? 0 : a.isHidden ? 1 : -1)) });
        setIsOpenModal(false);
    };

    const handlerSelectAll = () => {
        const allHidden = columns.every((col) => col.isHidden);
        const allVisible = columns.every((col) => !col.isHidden);

        setColumns((prev) =>
            prev.map((col) => ({
                ...col,
                isHidden: allHidden ? false : allVisible ? true : false,
            }))
        );
    };

    const isCheckedSelectAll = useMemo(() => !Boolean(columns.filter((col) => col.isHidden).length), [columns]);

    return (
        <div>
            <Button
                customClassName="setting-btn"
                variant="warning"
                size="small"
                withoutRounded
                onClick={openModal}
                title={<SettingIcon />}
            />

            <ModalWindow
                className="report-settings-modal"
                isVisible={isOpenModal}
                onClose={closeModal}
                anchorContainerID="report-page"
                closeButton
            >
                <h1>Отображаемые поля</h1>
                <div className={`select-all-block`}>
                    <input
                        id="select-all"
                        type="checkbox"
                        className="form-check-input"
                        checked={isCheckedSelectAll}
                        onChange={handlerSelectAll}
                    />
                    <label htmlFor="select-all">Выбрать все</label>
                </div>
                <SortableList items={columns} onSortEnd={onSortEnd} onToggle={handleToggle} helperClass="active" />
                <div className="buttons-block">
                    <Button onClick={closeModal} title="Отмена" withoutRounded variant="error" />
                    <Button withoutRounded title="Применить" onClick={handlerApplyBtn} />
                </div>
            </ModalWindow>
        </div>
    );
};

export default Setting;
