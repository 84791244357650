const style = {
    fill: "none",
    stroke: "#000000",
    strokeLinecap: "round",
    strokeLinejoin: "round",
    strokeWidth: 2,
};

const SecondUpdateIcon = () => (
    <svg fill="#000000" viewBox="0 0 24 24" id="update" xmlns="http://www.w3.org/2000/svg" >
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path id="primary" d="M4,12A8,8,0,0,1,18.93,8" style={style}></path>
            <path id="primary-2" d="M20,12A8,8,0,0,1,5.07,16" style={style}></path>
            <polyline id="primary-3" points="14 8 19 8 19 3" style={style}></polyline>
            <polyline id="primary-4" points="10 16 5 16 5 21" style={style}></polyline>
        </g>
    </svg>
);
export default SecondUpdateIcon;
