import Select from "react-select";

const SelectGroup = ({ initialGroups, selectedGroups, updateOptions }) => (
    <Select
        className="select small select-grouping"
        options={initialGroups}
        getOptionValue={(e) => e.field}
        placeholder="Группировка"
        value={initialGroups.filter((opt) => selectedGroups?.includes(opt.field))}
        onChange={(newValues) => updateOptions({ selectedGroups: newValues.map(({ field }) => field) })}
        isMulti
    />
);

export default SelectGroup;
